<template>
    <div class="grid">
        <div class="col-9">
            <h2>Anúncios</h2>
        </div>
        <div class="col-3" style="text-align:right">
            <Button label="Importar Anúncios" class="p-button-primary btn_cancel"  @click="importarAnuncio()"/>
        </div>
    </div>
    <div>        
        <Loading v-if="loading" />
        <div  v-if="!loading">
            <div class="grid marketplace" v-for="(marketplace, index) in marketplaces" :key="index">
                <div class="col-3" >    
                    <div class="titulo_marketplace">
                        <img :src="marketplace.imagem" class="img_marketplace" /> 
                        <div class="nome_marketplace">{{marketplace.nome}}
                        </div>
                    </div>
                </div>
                <div class="col-7">
                    <div class="grid">
                        <div class="col-4">
                            <div class="informacoes_anuncios">
                                <p class="numero">{{marketplace.anuncios_ativos}}</p>
                                <div class="titulo_informacoes">Anúncios Ativos</div>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="informacoes_anuncios">
                                <p class="numero">0</p>
                                <div class="titulo_informacoes">Produtos sem Anúncios</div>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="informacoes_anuncios">
                                <p class="numero">{{marketplace.nao_relacionados}}</p>
                                <div class="titulo_informacoes">Anúncios não Relacionados</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-2">
                    <a :href="'/anuncios-marketplace/'+marketplace.nome+'/'+marketplace.id">
                        <Button label="Visualizar Anúncios" class="p-button-success btn" />
                    </a>
                </div>
            </div>
        </div>
            
        </div>
        <div v-if="quantidadePaginas > 1" class="p-paginator p-component p-paginator-bottom" style="width:100%">
            <div 
            style="padding: 5px;cursor: pointer;" @click="primeiraPagina()"> Primeira </div><div style="padding: 5px;cursor: pointer;" @click="anteriorPagina()">Anterior</div>
            <div style="padding: 5px;cursor: pointer;" v-for="pagina in quantidadePaginas" :key="pagina" @click="paginaAtual(pagina)">{{ pagina }}</div>
            <div style="padding: 5px;cursor: pointer;" @click="proximaPagina()">Próxima</div>
            <div style="padding: 5px;cursor: pointer;" @click="ultimaPagina()">Ultima</div>
        </div>
    <Dialog header="Importar Anúncios por Produtos" v-model:visible="mostrarImportar" >
        <div class="flex flex-column">
            <h4>Marketplaces</h4>
            <div>
                <div v-for="mkt in this.marketplaces" :key="mkt.id" >
                    <div>
                        <input type="checkbox" id="scales" name="scales" >
                        <label for="scales">{{mkt.nome}}</label>
                    </div>
                </div>
            </div>

            <div style="text-align:center; width:100%; margin-top:10px;">
                <Button class="btn_success" label="Continuar" @click="salvarStatus(this.pedido_selecionado)"/>
                <Button class="btn_cancel" label="Fechar" @click="fecharStatus()" style="margin-right:5px"/> 
            </div>
        </div>
    </Dialog>
    <Toast position="top-left" />

</template>

<script>

import Api from '../service/api';
import Loading from '../components/Loading.vue';
import { resolve } from 'path';

	export default {

    data() {
        return {
            msg_fim_sessao:'A sessão expirou. Faça Login para voltar ao Hub.',
			msg_erro:'Erro ao se comunicar com o servidor. Verifique sua internet e tente novamente. Caso o problema persista entre em contato com o suporte.',
            empresa_id:0,
            api: "",
            loading: true,
            marketplaces: null,
            pagina: "1",
            quantidadePorPagina: "10",
            primeiroItemDaPagina: 1,
            ordenar: "id",
            tipoOrdenar: "DESC",
            totalLinhas: null,
            quantidades: ["10", "20", "50"],
            mostrarImportar:false,
            nomeColunas: [
                { nome: "Nome", value: "nome" },
                { nome: "Descrição", value: "descricao" },
                { nome: "sku", value: "sku" },
                { nome: "ean", value: "ean" },
                { nome: "Preço <br/>Normal", value: "preco_normal" },
                { nome: "Preço com <br/>Desconto", value: "preco_desconto" },
                { nome: "Altura", value: "altura" },
                { nome: "Largura", value: "largura" },
                { nome: "Profundidade", value: "profundidade" },
                { nome: "Peso", value: "peso" },
                { nome: "Estoque", value: "estoque" },
                { nome: "Video", value: "video" },
            ],
            produtosAdicionar: [
                { nome: "" },
                { descricao: "" },
                { sku: "" },
                { ean: "" },
                { preco_normal: "" },
                { preco_desconto: "" },
                { altura: "" },
                { largura: "" },
                { profundidade: "" },
                { peso: "" },
                { estoque: "" },
                { video: "" },
            ],
            mostrarAdicionar: false,
        };
    },
    produtosService: null,
    quantidadePaginas: 1,
    created() {
        this.empresa = this.$storage.getStorageSync("empresa")
        this.token = this.$storage.getStorageSync("jwt")
        this.empresa_id = this.empresa.id
        this.api = new Api();
     //   this.api.qtdAnuncios(this.empresa_id).then(data => { this.totalLinhas = data.data.quantidade })
        
    },
    mounted() {
        this.loading = false;
        this.listarIntegracoes();
    },
    methods: {
        listarIntegracoes() {
            this.loading = true;
            this.api.listarIntegracoes(this.token,this.empresa_id).then(data => {
                this.marketplaces = data.data
                console.log(data.data)
                resolve(this.loading = false )
                }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
        },
        importarAnuncio(){
            this.$router.push('importar-anuncios-produtos');
        }
    },
    components: { Loading }
}
</script>

<style scoped lang="scss">
@import '../assets/demo/badges.scss';
</style>
<style scoped lang="css">
input{
    margin: 10px;
}
.marketplace{
    padding:20px;
    background-color:#fff;
    margin-bottom:20px;
}

.card_marketplace{
    width:100%;
    padding:14px;
    border-radius: 5px;
    border: #000 solid 1px;
}

.titulo_marketplace{
    margin-top:15px;
    font-size: 14pt;
    font-weight: bold;
}

.informacoes_anuncios .numero{
    font-size: 15pt;
    font-weight: bold;
    margin-bottom:0;
}
.informacoes_anuncios .titulo_informacoes{
    font-size: 10pt;
    font-weight: bold;
}
.img_marketplace{
    float: left;
    border-radius: 50%;
    width:40px;
    height:40px;
    border: #ccc solid 1px;
    padding:5px;
    margin-right:5px;
    margin-top:-5px;
}
.nome_marketplace{
    float: left;
    margin-top: 0px;
}
p{
    margin:0;
}
.btn{
    background-color:#4e87ee; 
    margin-top:10px;
    border:none; 
    margin-right:10px;
}
.btn:hover{
    background-color:#4e87ee !important;
}

.btn_success{
    background-color:#4e87ee; 
    border:none; 
    margin-right:10px;
}
.btn_success:hover{
    background-color:#4e87ee; 
    border:none; 
    margin-right:10px;
}

.btn_cancel{
    background-color:#9d9d9d; 
    border:none
}
.btn_cancel:hover{
    background-color:#9d9d9d; 
    border:none
}
</style>
